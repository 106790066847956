<template>
    <Transition name="fadeup2">
        <div v-if="!loading" class="weather flex flex-col rounded-xl bg-[white] mt-10 p-4 shadow-lg m-10">
            <p class="text-primary-black font-bold ml-2 mb-2">Current weather in {{ currentWeather?.name }}</p>
            <div class="flex">
                <div
                    class="degrees min-w-[5rem] flex flex-col text-sm p-2 border-2 border-primary-lite rounded-xl justify-center items-center m-1 shadow-md uppercase font-bold">
                    <p class="text-[#ca6868]">{{ currentWeather?.main.temp_max }}°</p>
                    <p class="text-[#3f64c2]">{{ currentWeather?.main.temp_min }}°</p>
                </div>
                <div v-for="(weather, index) in currentWeather?.weather" :key="index"
                    class="degrees flex flex-col text-sm p-2 border-2 border-primary-lite rounded-xl justify-center items-center m-1 shadow-md uppercase font-bold">
                    <p class="text-primary-black font-bold flex items-center flex-inline justify-center"> <img
                            class="w-8" :src="'http://openweathermap.org/img/wn/' + weather.icon + '@2x.png'"></p>
                    <p class="text-gray">{{ weather.description }}</p>
                </div>
                <div
                    class="degrees min-w-[5rem] flex flex-col text-sm p-2 border-2 border-primary-lite rounded-xl justify-center items-center m-1 shadow-md uppercase font-bold">
                    <svg fill="#3da5ff" class="w-8 mt-2 mb-1 flex" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px"
                        viewBox="0 0 122.88 74.78" style="enable-background:new 0 0 122.88 74.78" xml:space="preserve">
                        <g>
                            <path
                                d="M28.69,53.38c-1.61,0-2.91-1.3-2.91-2.91c0-1.61,1.3-2.91,2.91-2.91h51.37c0.21,0,0.42,0.02,0.62,0.07 c1.84,0.28,3.56,0.8,5.1,1.63c1.7,0.92,3.15,2.19,4.27,3.89c3.85,5.83,3.28,11.24,0.56,15.24c-1.77,2.61-4.47,4.55-7.45,5.57 c-3,1.03-6.32,1.13-9.32,0.03c-4.54-1.66-8.22-5.89-8.76-13.55c-0.11-1.6,1.1-2.98,2.7-3.09c1.6-0.11,2.98,1.1,3.09,2.7 c0.35,4.94,2.41,7.56,4.94,8.48c1.71,0.62,3.67,0.54,5.48-0.08c1.84-0.63,3.48-1.79,4.52-3.32c1.49-2.19,1.71-5.28-0.61-8.79 c-0.57-0.86-1.31-1.51-2.18-1.98c-0.91-0.49-1.97-0.81-3.13-0.99H28.69L28.69,53.38z M15.41,27.21c-1.61,0-2.91-1.3-2.91-2.91 c0-1.61,1.3-2.91,2.91-2.91h51.21c1.17-0.18,2.23-0.5,3.14-0.99c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79 c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7 c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57 c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H15.41 L15.41,27.21z M2.91,40.3C1.3,40.3,0,38.99,0,37.39c0-1.61,1.3-2.91,2.91-2.91h107.07c1.17-0.18,2.23-0.5,3.13-0.99 c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08 c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55 c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89 c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H2.91L2.91,40.3z" />
                        </g>
                    </svg>
                    <p class="text-gray">{{ currentWeather?.wind.speed }} m/s</p>
                </div>
                <div
                    class="degrees min-w-[5rem] flex flex-col text-sm p-2 border-2 border-primary-lite rounded-xl justify-center items-center m-1 shadow-md uppercase font-bold">
                    <svg class="w-8" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.984 511.984"
                        style="enable-background:new 0 0 511.984 511.984;" xml:space="preserve">
                        <g>
                            <path style="fill:#F6BB42;"
                                d="M95.998,282.643H32c-5.891,0-10.664,4.781-10.664,10.688c0,5.875,4.773,10.656,10.664,10.656h63.998c5.89,0,10.663-4.781,10.663-10.656C106.661,287.425,101.888,282.643,95.998,282.643z" />
                            <path style="fill:#F6BB42;"
                                d="M479.985,282.643h-63.997c-5.891,0-10.656,4.781-10.656,10.672s4.766,10.672,10.656,10.672h63.997c5.891,0,10.656-4.781,10.656-10.656C490.641,287.425,485.876,282.643,479.985,282.643z" />
                        </g>
                        <path style="fill:#FFCE54;"
                            d="M406.832,127.39l-45.25,45.257c-4.155,4.164-4.155,10.914,0,15.077c4.156,4.172,10.922,4.172,15.094,0l45.249-45.248c4.156-4.164,4.156-10.922,0-15.086C417.769,123.226,411.004,123.226,406.832,127.39z" />
                        <path style="fill:#F6BB42;"
                            d="M245.328,69.33v63.998c0,5.891,4.773,10.664,10.664,10.664c5.891,0,10.664-4.773,10.664-10.664V69.33c0-5.89-4.773-10.671-10.664-10.671S245.328,63.44,245.328,69.33z" />
                        <g>
                            <path style="fill:#FFCE54;"
                                d="M90.068,142.476l45.248,45.256c4.164,4.164,10.922,4.164,15.086,0c4.164-4.163,4.164-10.921,0-15.085l-45.257-45.257c-4.164-4.164-10.913-4.164-15.077,0C85.896,131.555,85.896,138.312,90.068,142.476z" />
                            <path style="fill:#FFCE54;"
                                d="M146.426,335.985c-5.305-13.562-7.984-27.89-7.984-42.654c0-64.826,52.732-117.559,117.551-117.559c64.812,0,117.559,52.732,117.559,117.559c0,14.765-2.688,29.093-8,42.654H146.426z" />
                        </g>
                        <path style="fill:#F6BB42;"
                            d="M255.992,165.1c-70.811,0-128.215,57.405-128.215,128.23c0,19.03,4.148,37.076,11.586,53.311h233.25c7.438-16.234,11.594-34.28,11.594-53.311C384.207,222.506,326.803,165.1,255.992,165.1z M358.02,325.329H153.972c-3.234-10.296-4.867-21.014-4.867-31.998c0-28.562,11.116-55.405,31.311-75.591c20.188-20.188,47.022-31.304,75.576-31.304c28.562,0,55.39,11.116,75.576,31.304c20.202,20.186,31.311,47.029,31.311,75.591C362.879,304.315,361.238,315.033,358.02,325.329z" />
                        <path style="fill:#ED5564;"
                            d="M286.163,393.733l-22.632-22.624l0,0c-1.938-1.938-4.593-3.125-7.539-3.125l0,0c-2.938,0-5.602,1.188-7.531,3.109l-0.008,0.016l-22.632,22.624c-4.164,4.156-4.164,10.906,0,15.094c2.086,2.063,4.813,3.109,7.547,3.109c2.727,0,5.461-1.047,7.538-3.109l4.422-4.438v38.249c0,5.906,4.773,10.687,10.664,10.687l0,0c5.891,0,10.664-4.78,10.664-10.687v-38.249l4.422,4.438c2.07,2.078,4.82,3.125,7.539,3.125c2.733,0,5.468-1.047,7.546-3.125C290.335,404.655,290.335,397.889,286.163,393.733z" />
                        <path style="fill:#656D78;"
                            d="M501.328,325.329H10.664C4.773,325.329,0,330.095,0,335.985s4.773,10.656,10.664,10.656h490.664c5.875,0,10.656-4.766,10.656-10.656S507.203,325.329,501.328,325.329z" />
                    </svg>
                    <p class="text-gray">{{ getTimeOfDay(currentWeather?.sys.sunrise) }}</p>
                </div>
                <div
                    class="degrees min-w-[5rem] flex flex-col text-sm p-2 border-2 border-primary-lite rounded-xl justify-center items-center m-1 shadow-md uppercase font-bold">
                    <svg version="1.1" class="w-8" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.984 511.984"
                        style="enable-background:new 0 0 511.984 511.984;" xml:space="preserve">
                        <g>
                            <path style="fill:#F6BB42;"
                                d="M95.998,282.643H32c-5.891,0-10.664,4.781-10.664,10.688c0,5.875,4.773,10.656,10.664,10.656h63.998c5.89,0,10.663-4.781,10.663-10.656C106.661,287.425,101.888,282.643,95.998,282.643z" />
                            <path style="fill:#F6BB42;"
                                d="M479.985,282.643h-63.997c-5.891,0-10.656,4.781-10.656,10.688c0,5.875,4.766,10.656,10.656,10.656h63.997c5.891,0,10.656-4.781,10.656-10.656C490.641,287.425,485.876,282.643,479.985,282.643z" />
                        </g>
                        <path style="fill:#FFCE54;"
                            d="M406.832,127.39l-45.25,45.257c-4.155,4.164-4.155,10.914,0,15.085c4.156,4.164,10.922,4.164,15.094,0l45.249-45.256c4.156-4.164,4.156-10.922,0-15.086C417.754,123.226,411.004,123.226,406.832,127.39z" />
                        <path style="fill:#F6BB42;"
                            d="M245.328,69.33v63.998c0,5.891,4.773,10.664,10.664,10.664c5.891,0,10.664-4.773,10.664-10.664V69.33c0-5.89-4.773-10.671-10.664-10.671S245.328,63.44,245.328,69.33z" />
                        <g>
                            <path style="fill:#FFCE54;"
                                d="M90.068,142.476l45.248,45.256c4.164,4.164,10.922,4.164,15.086,0c4.164-4.171,4.164-10.921,0-15.085l-45.257-45.257c-4.164-4.164-10.913-4.164-15.077,0C85.896,131.555,85.896,138.312,90.068,142.476z" />
                            <path style="fill:#FFCE54;"
                                d="M146.426,335.985c-5.305-13.562-7.984-27.89-7.984-42.654c0-64.826,52.732-117.559,117.551-117.559c64.812,0,117.559,52.732,117.559,117.559c0,14.765-2.688,29.093-8,42.654H146.426z" />
                        </g>
                        <path style="fill:#F6BB42;"
                            d="M255.992,165.1c-70.811,0-128.215,57.405-128.215,128.23c0,19.03,4.148,37.076,11.586,53.311h233.25c7.438-16.234,11.594-34.28,11.594-53.311C384.207,222.506,326.803,165.1,255.992,165.1z M358.02,325.329H153.972c-3.234-10.296-4.867-21.014-4.867-31.998c0-28.562,11.116-55.405,31.311-75.591c20.188-20.188,47.022-31.304,75.576-31.304c28.562,0,55.39,11.116,75.576,31.304c20.202,20.186,31.311,47.029,31.311,75.591C362.879,304.315,361.238,315.033,358.02,325.329z" />
                        <path style="fill:#ED5564;"
                            d="M225.821,427.561l22.632,22.64c0,0,0,0,0.008,0c1.93,1.921,4.594,3.124,7.531,3.124l0,0c2.938,0,5.602-1.203,7.531-3.124h0.008l22.632-22.624c4.172-4.172,4.172-10.938,0-15.094c-2.078-2.094-4.813-3.125-7.546-3.125c-2.719,0-5.453,1.031-7.539,3.125l-4.422,4.406V378.64c0-5.875-4.773-10.656-10.664-10.656l0,0c-5.891,0-10.664,4.781-10.664,10.656v38.249l-4.422-4.406c-2.077-2.094-4.812-3.125-7.538-3.125c-2.734,0-5.461,1.031-7.547,3.125C221.657,416.639,221.657,423.389,225.821,427.561z" />
                        <path style="fill:#656D78;"
                            d="M501.328,325.329H10.664C4.773,325.329,0,330.095,0,335.985s4.773,10.656,10.664,10.656h490.664c5.875,0,10.656-4.766,10.656-10.656S507.203,325.329,501.328,325.329z" />
                    </svg>
                    <p class="text-gray">{{ getTimeOfDay(currentWeather?.sys.sunset) }}</p>
                </div>
            </div>
        </div>

    </Transition>

</template>

<script>
import axios from 'axios'

export default {
    name: 'weather-component',
    data() {
        return {
            currentWeather: null,
            loading: true,
        }
    },
    mounted() {
        this.getCurrentWeather()
    },
    methods: {
        getCurrentWeather() {
            axios.get('https://api.openweathermap.org/data/2.5/weather?lat=56.176361&units=metric&lon=9.554922&appid=84b5965a9761a2316f2649a79129437d').then(res => {
                this.currentWeather = res.data;
                this.loading = false;
            })
        },
        getTimeOfDay(time) {
            return (new Date(time).getHours() + ':' + new Date(time).getMinutes())
        }
    }
}
</script>

<style lang="scss" scoped>
.fadeup2-enter-active {
    transition: all 2s ease-out;
}

.fadeup2-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeup2-enter-from,
.fadeup2-leave-to {
    transform: translateY(40px);
    opacity: 0;
}

</style>