<template>
    <Transition name="fadeup3">
        <div v-show="show" class="w-full flex justify-center">
            <div class="todolist flex justify-center m-5 w-full sm:w-[531px]">
                <div class="center w-full">
                    <section class="todo-cmp rounded-xl p-4 shadow-lg">
                        <header class="todo-cmp__header">
                            <h2>{{ dateData.day }}</h2>
                            <p>{{ dateData.date }}</p>
                        </header>
                        <ul v-for="item in items" :key="item.id" class="todo-cmp__list mt-5">
                            <li>
                                <label :id="'todo-' + item.id">
                                    <input :id="'todo-' + item.id" type="checkbox">
                                    <span>{{ item.todo }}</span>
                                </label>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>

    </Transition>
</template>

<script>
export default {
    name: 'todo-list',
    data() {
        return {
            show: false,
            dateData: {
                day: new Date().toLocaleDateString('en-GB', { weekday: 'long' }),
                date: new Date().toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }),
            },
            items: [
                {id: 1, todo: "Open template"},
                {id: 2, todo: "Build a to-do list"},
                {id: 3, todo: "Write something"},
                {id: 4, todo: "Complete the task"},
            ]
        }
    },
    mounted() {
        setTimeout(() => {
            this.show = true;
        }, 600)
    }
}
</script>

<style lang="scss" scoped>
// delete the following line if no text is used
// edit the line if you wanna use other fonts

.todo-cmp {
    background: #ffffff;
    color: theme('colors.primary');
    padding: 10px 30px;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
    max-width: 531px;

    &__header {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;


        h2 {
            font-weight: 600;
            font-size: 1.2rem;
            margin: 4px auto;
            padding: 0;
        }

        p {
            padding: 0 0 5px;
            margin: 4px auto;
            font-size: 0.8rem;
        }
    }

    &__list {
        list-style: none;
        padding: 0;

        li {
            padding: 10px 0 15px;
            margin: 0;
            text-align: left;
            width: 100%;

            label {
                cursor: pointer;
                font-size: 0.82rem;
                width: 100%;
                display: block;


                input {
                    float: right;
                    opacity: 0;
                }

                span {
                    position: relative;
                    display: block;
                    font-size: 1rem;
                    transition: all 550ms ease-in-out;
                }


                input+span::after {
                    content: "";
                    display: block;
                    border: 1px solid theme('colors.primary');
                    border-radius: 13px;
                    height: 13px;
                    width: 13px;
                    position: absolute;
                    right: 0;
                    top: 1px;
                    z-index: 10;

                    transition: all 550ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
                    transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
                    /* https://matthewlein.com/tools/ceaser */
                }

                input+span::before {
                    content: "✔";
                    display: block;
                    font-size: 22px;
                    height: 13px;
                    width: 13px;
                    position: absolute;
                    right: -2px;
                    top: -8px;
                    z-index: 10;
                    opacity: 0;
                }

                input:checked+span {
                    color: #ccc;
                    transition: all 550ms ease-in-out;
                }

                input:checked+span::after {
                    opacity: 0;
                    height: 17px;
                    width: 17px;
                    top: -2px;
                    right: -2px;
                }

                input:checked+span::before {
                    opacity: 1;
                    transition: all 550ms ease-in-out;
                }
            }
        }
    }
}

.fadeup3-enter-active {
    transition: all 1.5s ease-out;
}

.fadeup3-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeup3-enter-from,
.fadeup3-leave-to {
    transform: translateY(40px);
    opacity: 0;
}
</style>