<template>
<!--   <div>
    <p class="hidden sm:block">sm</p>
    <p class="hidden md:block">md</p>
    <p class="hidden lg:block">lg</p>
  </div> -->
  <div class="landingpage flex flex-col h-full">
    <div class="flex w-full p-4 justify-between">
      <img class="h-16" src="@/assets/logo.png">
      <button class="btn-grad shadow-xl uppercase font-bold">Contact</button>
    </div>
    <!--    <div class="flex w-full justify-end p-4">
  
    </div> -->
    <div class="flex w-full h-full">
      <div class="lg:w-1/2 sm:w-full flex flex-col items-center justify-start md:w-full w-full">
        <div class="flex flex-col relative h-20 md:h-32 sm:h-24 -ml-24">
          <Transition>
            <h1 v-show="animation.headline"
              class="text-[white] font-normal lg:text-8xl md:text-8xl sm:text-8xl text-6xl">KeepFocus
            </h1>
          </Transition>
          <Transition name="slide-fade">
            <h2 v-show="animation.subtext"
              class="text-primary font-bold text-2xl lg:text-4xl sm:text-3xl self-end absolute top-12 sm:top-20 -right-10 md:top-20 md:-right-16">
              Weather &
              Todo</h2>
          </Transition>

        </div>
        <weather></weather>
        <Todolist></Todolist>
      </div>
      <div class="hidden sm:hidden md:hidden lg:flex w-1/2 items-center justify-center">
        <Transition name="fadeup">
          <img class="w-3/4" v-show="animation.headline"
            src="https://s3-eu-west-1.amazonaws.com/pubfront-mini-web/media/images/iPhone_Skewed_Copy.original.png" />
        </Transition>
      </div>
    </div>

  </div>
</template>

<script>
import weather from './components/weather.vue'
import todolist from './components/todolist.vue'
import Todolist from './components/todolist.vue'

export default {
  name: 'App',
  components: {
    weather,
    todolist,
    Todolist
},
  data() {
    return {
      animation: {
        headline: false,
        subtext: false,
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.animation.headline = true;
      setTimeout(() => {
        this.animation.subtext = true;
      }, 300)
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
.btn-grad {
  background-image: linear-gradient(to right, #FF512F 0%, #F09819 51%, #FF512F 100%);
  margin: 10px;
  padding: 15px 45px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  transform: scale(1.03)
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fadeup-enter-active {
  transition: all 1s ease-out;
}

.fadeup-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeup-enter-from,
.fadeup-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>